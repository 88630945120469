@import url("https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap");

header {
  height: 100vh;
  background-image: url(../../../src/assets/hero-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -3rem;
}

.header__container {
  position: relative;
  margin: 0 auto;
  top: 50%;
}

.header__content {
  margin: auto;
  display: flex;
}

.name__second-row {
  margin-left: 1.5rem;
}

.heading__name {
  margin-right: 3.75rem;
}

h1 {
  font-size: 6rem;
  line-height: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-weight: 600;
  text-shadow: white -2px 2px;
}

.position__text {
  margin: auto 0;
  border-left: 2px solid var(--color-secondary);
  width: 30%;
}

.position__text p {
  margin-left: 3.5rem;
  text-shadow: white -1px 1px;
}

p {
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.5rem;
}


.header__aimee-img img {
  width: 100%;
}

/* ================ CTA =============== */

.cta {
  margin: 1.5rem 0 1.5rem 1.5rem;
  display: flex;
  gap: 1.2rem;
}

/* =============== HEADER SOCIALS ================= */

.header__socials {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2rem;
  position: absolute;
  left: -11.5rem;
  bottom: -30rem;
  font-size: 2rem;
}

.header__socials::after {
  content: "";
  width: 5px;
  height: 10rem;
  background: var(--color-white);
  border-radius: 50px;
}

/* ============== MEDIA QUERIES (EXTRA-LARGE DEVICES) =============== */

@media screen and (max-width: 2000px) {
  .aimee_logo {
    left: 9rem;
    width: 5rem;
  }
}

/* ============== MEDIA QUERIES (LARGE DEVICES) =============== */

@media screen and (max-width: 1400px) {
  .container.header__container {
    width: 90%;
  }
  .heading__name {
    margin-right: 3rem;
  }
  h1 {
    font-size: 4rem;
    line-height: 2.5rem;
  }
  p {
    font-size: 1.2rem;
  }
  .position__text {
    width: 100%;
  }
  .position__text p {
    margin-left: 2.5rem;
  }
  .header__socials {
    left: -1rem;
    bottom: -21.5rem;
    font-size: 1.75rem;
  }
  .aimee_logo {
    left: 2.5rem;
    width: 4rem;
  }
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
  .header__container {
    grid-template-columns: 1fr;
  }
  .header__aimee-img {
    width: 60%;
    margin: 2rem auto;
  }
  h1 {
    font-size: 3rem;
    line-height: 4rem;
  }
  p {
    font-size: 1.2rem;
  }
  .header__socials {
    left: 0rem;
  }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 768px) {
  header {
    height: 46vh;
    background-position: right;
  }
  .header__overlay {
    background-color: #1C1C1C;
    width: 100%;
    height: 38%;
    position: absolute;
    opacity: 0.25;
  }
  .heading__name h1 {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
  }
  .heading__name h1 span {
    text-shadow: none;
    color: var(--color-white);
    line-height: 1rem;
  }
  .name__second-row {
    margin-left: -0.75rem;
  }
  .position__text {
    border-left: none;
  }
  .position__text p {
    margin-left: 0;
    color: var(--color-white);
    text-shadow: none;
  }
  .header__content {
    flex-direction: column;
  }
  .header__container {
    grid-template-columns: 1fr;
  }
  .header__aimee-img {
    width: 90%;
    margin: 1rem auto;
  }
  h1 {
    margin-left: 0;
    margin-bottom: 0.5rem;
    font-size: 2.25rem;
    line-height: 3rem;
  }
  p {
    font-size: 1rem;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
  }
  .cta {
    display: none;
  }
  .header__socials {
    display: none;
  }
  .tag {
    display: none;
  }

  .aimee_logo {
    display: none;
  }
}
