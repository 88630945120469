#skills {
  background-color: #252524;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: -42rem;
  padding: 0 3rem;
  padding-bottom: 4rem;
}

.skills-image__container {
  display: flex;
}

.left-image__illustration {
  margin: auto;
}

.left-image__illustration img {
  width: 100%;
}

.skills__container {
  display: flex;
  flex-direction: column;
  padding: 3rem 0 5rem;
}

.skill__cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}

.skills__item {
  width: 14rem;
  height: 8.5rem;
  padding: 2.5rem;
  border-radius: 1rem;
  margin-bottom: 6rem;
  margin: 0 auto;
  transition: var(--transition);
}

.skills__item img {
  width: 3.75rem;
  margin: 0 auto;
}

/* Logos */

.html {
  -webkit-box-shadow: 0px 19px 26px 3px #e4563384;
  box-shadow: 0px 19px 26px 3px #e4563384;
}

.html:hover {
  background: #e4563384;
}

.css {
  -webkit-box-shadow: 0px 19px 26px 3px #0278bd76;
  box-shadow: 0px 19px 26px 3px #0278bd76;
}

.css:hover {
  background: #0278bd76;
}

.sass {
  -webkit-box-shadow: 0px 19px 26px 3px #cd679879;
  box-shadow: 0px 19px 26px 3px #cd679879;
}

.sass img {
  transform: translateY(20%);
}

.sass:hover {
  background: #cd679879;
}

.javascript {
  -webkit-box-shadow: 0px 19px 26px 3px #ffd5007b;
  box-shadow: 0px 19px 26px 3px #ffd5007b;
}

.javascript:hover {
  background: #ffd5007b;
}

.php {
  -webkit-box-shadow: 0px 19px 26px 3px #777bb379;
  box-shadow: 0px 19px 26px 3px #777bb379;
}

.php img {
  transform: translateY(40%);
}

.php:hover {
  background: #777bb379;
}

.wordpress {
  -webkit-box-shadow: 0px 19px 26px 3px #41a0c677;
  box-shadow: 0px 19px 26px 3px #41a0c677;
}

.wordpress:hover {
  background: #41a0c677;
}

.react {
  -webkit-box-shadow: 0px 19px 26px 3px #00d8fe60;
  box-shadow: 0px 19px 26px 3px #00d8fe60;
}

.react:hover {
  background: #00d8fe60;
}

.typescript {
  -webkit-box-shadow: 0px 19px 26px 3px #3179c670;
  box-shadow: 0px 19px 26px 3px #3179c670;
}

.typescript:hover {
  background: #3179c670;
}

.github {
  -webkit-box-shadow: 0px 19px 26px 3px #eeeeee68;
  box-shadow: 0px 19px 26px 3px #eeeeee68;
}

.github:hover {
  background: #eeeeee68;
}

.git {
  -webkit-box-shadow: 0px 19px 26px 3px #f04f3376;
  box-shadow: 0px 19px 26px 3px #f04f3376;
}

.git:hover {
  background: #f04f3376;
}

.skills__header-content {
  text-align: center;
  margin: 3rem 0 0;
}

.skills__header-content h5 {
  width: fit-content;
  margin: auto;
  border: 1px solid var(--color-tertiary);
  border-radius: 35px;
  padding: 0.1rem 1.5rem;
  font-weight: 500;
  font-size: 1rem;
}

/* ============== MEDIA QUERIES (EXTRA-LARGE DEVICES) =============== */

@media screen and (max-width: 2000px) {
  #skills {
    background-position-y: -45rem;
  }

  .skills__container {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
    padding-right: 1rem;
  }

  .skills__item {
    margin: 1.5rem auto;
    height: 8rem;
    width: 15.5rem;
    padding: 2.2rem;
  }

  .skills__item img {
    width: 3.5rem;
  }
}

/* ============== MEDIA QUERIES (LARGE DEVICES) =============== */

@media screen and (max-width: 1400px) {
  #skills {
    background-position-y: -54.4rem;
  }

  .skill__cards {
    grid-template-columns: repeat(3, 1fr);
    gap: 0.875rem;
  }

  .skills__item {
    margin: 1.5rem auto;
    height: 6.5rem;
    width: 13rem;
    padding: 1.9rem;
  }

  .skills__container {
    padding: 1rem;
  }

  .skills__item img {
    width: 3rem;
  }
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
  #skills {
    background-position-y: -65rem;
  }

  .skills__container {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
  }

  .skills__item {
    margin: 1.5rem auto;
  }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 768px) {
  #skills {
    background: none;
    padding: 0;
  }

  #skills .container {
    width: 97%;
  }

  .left-image__illustration {
    display: none;
  }

  .skills__header-content {
    margin: 0rem 0 1rem;
  }

  .skills__header-content h2 {
    margin-bottom: 0.5rem;
  }

  .skills__container {
    grid-template-columns: 1fr 1fr;
    gap: 0;
    padding-top: 0;
    padding-bottom: 1rem;
  }

  .skills__content {
    margin-top: 0;
  }

  .skill__cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .skills__item {
    margin: 0.75rem 0;
    height: 6rem;
    width: 10rem;
    padding: 1.5rem;
  }

  .skills__item img {
    width: 3rem;
  }
}
