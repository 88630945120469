/* General Navbar Styling */
.navbar {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  padding: 3.5rem 3rem;
  background-color: transparent;
  height: 60px;
}

/* Logo and Links Styling */
.navbar-content {
  display: flex;
  align-items: center;
  gap: 30px;
}

/* Logo Styling */
.aimee_logo {
  height: 105px;
  object-fit: contain;
}

/* Navigation Links */
.navbar-links {
  display: flex;
  gap: 20px;
  list-style: none;
}

.navbar-links li {
  font-size: 16px;
  margin: auto;
}

.navbar-links a {
  position: relative;
  text-decoration: none;
  color: #5E5F50;
  font-weight: 500;
  padding-bottom: 5px; 
  transition: color 0.3s ease;
}

.navbar-links a:hover {
  color: #5E5F50;
}

/* Animated Underline */
.navbar-links a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #95967c;
  transition: width 0.3s ease;
}

.navbar-links a:hover::after {
  width: 100%; 
}

/* Button Styling */
.navbar-button {
  background-color: #7F8065;
  color: white;
  border: none;
  padding: 10px 25px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.5s ease-in-out;
}

.navbar-button .icon {
  font-size: 18px;
}

.navbar-button:hover {
  background-color: #64644f;
}

@media screen and (max-width: 768px) {
  .navbar {
    display: none;
  }
}