#contact {
  background: linear-gradient(178.65deg, #272726 -23.45%, #272726 21.59%, rgba(44, 44, 42, 0.59) 63.15%, #3BAFCE 155.69%);
}

.container.contact__container {
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
  padding-bottom: 5rem;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  background: #222222;
  padding: 2.8rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact__option h3 {
  color: #CEBFA8;
}

.contact__option h5 {
  color: var(--color-white);
  font-size: 1rem;
}

.contact__option svg {
  font-size: 1.75rem;
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact__header {
  text-align: center;
  margin: 5rem 0 4rem;
}

.contact__header h5 {
  font-size: 1rem;
  margin: auto;
  width: fit-content;
  border: 1px solid var(--color-tertiary);
  border-radius: 35px;
  padding: 0.1rem 1.5rem;
  font-weight: 500;
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.contact__option a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 1rem;
  color: #CEBFA8;
}

/* =================== FORM ==================== */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 1px solid var(--color-white);
  resize: none;
  color: var(--color-white);
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 768px) {
  .container.contact__container {
    width: var(--container-width-sm);
    padding-bottom: 0;
  }
  .contact__header {
    margin: 2rem 0 2rem;
  }
  .contact__container button {
    width: 100%;
  } 

  input,
  textarea {
    font-size: 0.8rem;
  }
}
