#projects {
  background: linear-gradient(178.65deg, #5870FF -23.45%, rgba(34, 34, 34, 0.74) 21.59%, rgba(44, 44, 42, 0.59) 83.15%, #222222 126.69%);
  padding-bottom: 6rem;
}

.project__item-text {
  position: absolute;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  margin-right: 10px; /* Adjust the margin as needed */
  font-size: 2.8rem;
  font-weight: 500;
  left: -4.5rem;
}
.project__item-text.fellow-company {
  text-shadow: #5870FF 0 -4px;
}
.project__item-text.sultans-company {
  text-shadow: #36AD7B 0 -4px;
}

.fellow-company {
  top: 65%;
}

.sultans-company {
  top: 16%;
}

.project__container {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}

.projects__header {
  text-align: center;
  margin: 3rem 0 3rem;
}
.projects__header h5 {
  width: fit-content;
  margin: auto;
  border: 1px solid var(--color-tertiary);
  border-radius: 35px;
  padding: 0.1rem 1.5rem;
  font-weight: 500;
  font-size: 1rem;
}

.project__item {
  background-color: #2c2c2c;
  padding: 1.875rem;
  border-radius: 10px;
  border: 1px solid transparent;
  transition: var(--transition);
}

.project__item.fellow-company:hover {
  border-color: #596FFD;
}
.project__item.sultans-company:hover {
  border-color: #B29AF1;
}

.project__item.fellow-company h3 {
  color: #8B9AFF;
  font-weight: 600;
}
.project__item.sultans-company h3 {
  color: #B29AF1;
  font-weight: 600;
}

.project__item h4 {
  color: var(--color-white);
  padding: 0;
  height: 7.5rem;
  font-size: 1.1rem;
}

.project__item h3 {
  margin: 1.25rem 0 0.5rem;
  font-size: 1.65rem;
}

.project__item-image {
  border-radius: 5px;
  overflow: hidden;
}
.project__item-image img {
  width: 100%;
  height: 15.5rem;
}

.project__item-cta {
  text-align: center;
  display: flex;
  gap: 1rem;
  margin: 1.5rem 0 0 0;
  justify-content: center;
}

.fellow-company .project__item-cta a {
  background-color: #2A3FCC;
}
.fellow-company .project__item-cta a:hover {
  background-color: #1e2c8a;
  color: var(--color-white);
}
.sultans-company .project__item-cta a {
  background-color: #7451D2;
}
.sultans-company .project__item-cta a:hover {
  background-color: #46327d;
  color: var(--color-white);
}

.project__item-cta a {
  border: none;
  width: 100%;
  color: var(--color-white);
  font-weight: 500;
  font-size: 1.1rem;
}

/* ============== MEDIA QUERIES (LARGE DEVICES) =============== */

@media screen and (max-width: 1400px) {
  .project__container {
    grid-template-columns: repeat(3, 1fr);
  }
  .projects__header {
    margin: 3rem 0 2.5rem;
  }
  .project__item > h4 {
    padding: 0;
  }
  .project__item h3 {
    font-size: 1.5rem;
  }
  .project__item h4 {
    font-size: 1rem;
  }
  .project__item-text {
    display: none;
  }
  #projects .container {
    width: 95%;
  }
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
  .project__container {
    grid-template-columns: repeat(2, 1fr);
  }
  .projects__header {
    margin: 3rem 0 2.5rem;
  }
  .project__item > h4 {
    padding: 0;
  }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 768px) {
  .project__item-text {
    display: none;
  }
  .project__container {
    grid-template-columns: 1fr;
  }
  .projects__header {
    margin: 2rem 0 2rem;
  }
  .project__item > h4 {
    padding: 0;
  }
  .project__item h3 {
    font-size: 1.5rem;
    text-align: center;
  }
  .project__item h4 {
    font-size: 1rem;
    text-align: center;
  }
  .project__item-cta a {
    align-items: center;
    display: grid;
    font-size: 1rem;
  }
}
