#about {
  background-color: #272726;
}

.about__container {
  gap: 5rem;
  position: relative;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.about__content {
  padding-top: 3rem;
}

/* =============== IMAGE CONTAINER ================= */

.about__me {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  border-radius: 2rem;
}

.about__me-image {
  width: 100%;
}

.about__header-content {
  text-align: center;
  margin-bottom: 2rem;
}
.about__header-content h5 {
  font-size: 1rem;
  margin: auto;
  width: fit-content;
  border: 1px solid var(--color-tertiary);
  border-radius: 35px;
  padding: 0.1rem 1.5rem;
  font-weight: 500;
}

.about__me-image {
  border-radius: 2rem;
  overflow: hidden;
  transition: var(--transition);
}

.about__me-image img {
  width: 100%;
}

/* ============ CARD CONTENT ============ */

.about__icon {
  color: var(--color-tertiary);
  font-size: 1.4rem;
  margin-bottom: 0.25rem;
}

small {
  color: var(--color-white);
}

.about__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}

.about__card {
  text-align: center;
  background: #222222;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  padding: 2rem 1.25rem;
  transition: var(--transition);
}

.about__card h5 {
  font-weight: 500;
}

.about__card:hover {
  cursor: default;
  background: transparent;
  border-color: var(--color-primary);
}

.about__text {
  margin: 2rem 0;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.video_link {
  color: var(--color-tertiary);
}

/* ============== MEDIA QUERIES (EXTRA-LARGE DEVICES) =============== */

@media screen and (max-width: 2000px) {
  .about__text {
    font-size: 1.1rem;
  }
}

/* ============== MEDIA QUERIES (LARGE DEVICES) =============== */

@media screen and (max-width: 1400px) {
  .about__me {
    height: 43%;
  }

  .container.about__container {
    width: 90%;
  }

  .about__text {
    font-size: 1rem;
  }
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) =============== */
@media screen and (max-width: 1024px) {
  #about {
    height: 111vh;
  }

  .about__container {
    grid-template-columns: 1fr;
    gap: 0;
  }

  .about__me {
    width: 50%;
    height: 55%;
    margin: 1rem auto 1rem;
  }

  .about__content p {
    margin: 2rem 0 3.5rem;
    font-size: 1rem;
  }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) =============== */
@media screen and (max-width: 768px) {
  #about {
    height: fit-content;
  }

  .about__me {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .about__cards {
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .about__content {
    text-align: left;
    padding-top: 1.5rem;
  }

  .about__content {
    margin: 0;
  }
  .about__content p {
    margin: 2rem 0 0;
  }
}
