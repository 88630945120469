@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

body {
  background-color: #252524 !important;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

:root {
  --color-bg: #020202;
  --color-bg-variant: #161616;
  --color-primary: #5E5F50;
  --color-secondary: #7F8065;
  --color-tertiary: #CCCDB1;
  --color-white: #fff;
  --color-light: #c2c2c2;

  --transition: all 400ms ease;

  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}

.cursor {
  width: 3rem;
  height: 3rem;
  border: 1.5px solid var(--color-primary);
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: all 0.3s ease;
  transition-property: background, transform;
  transform-origin: 100% 100%;
  z-index: 5;
}

body {
  font-family: 'Raleway', sans-serif;
  background: var(--color-bg);
  color: var(--color-white);
  line-height: 1.7;
}

/* ================== GENERAL STYLES ==================== */

.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

section {
  padding-top: 4rem;
}

.vertical-center {
  margin: 0 auto;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.vertical-off-center {
  margin: 0 auto;
  top: 45%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.tag {
  font-family: "La Belle Aurore", cursive;
  font-size: 1.5rem;
  color: var(--color-tertiary);
  font-weight: 500;
}

.indent {
  margin-left: 1.5rem;
}

h5 {
  color: var(--color-tertiary);
  font-weight: 300;
  font-size: 1.1rem;
}

h3 {
  color: var(--color-primary);
  font-weight: 500;
  font-size: 1.25rem;
}

h4 {
  color: var(--color-light);
  padding: 0 2rem;
  font-weight: 300;
}

h2 {
  color: var(--color-white);
  font-weight: 600;
  font-size: 2rem;
}

a {
  color: var(--color-white);
  transition: var(--transition);
}

a:hover {
  color: var(--color-white);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  font-weight: 500;
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
  font-size: 1rem;
}

.btn:hover {
  background: var(--color-white);
  color: var(--color-bg);
  border-color: transparent;
}

.btn-primary {
  background: #222222;
  border: none;
  color: var(--color-white);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  height: 3.25rem;
  width: 20rem;
  letter-spacing: 1px;
}

img {
  display: block;
  object-fit: cover;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) =============== */

@media screen and (max-width: 1024px) {
  .cursor {
    display: none;
  }

  .container {
    width: var(--container-width-md);
  }

  .section {
    padding-top: 2rem;
  }

  .btn {
    padding: 0.5rem 1rem;
  }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) =============== */

@media screen and (max-width: 768px) {
  #root {
    overflow: hidden;
  }

  .cursor {
    display: none;
  }

  section {
    padding-top: 1rem;
    padding-bottom: 3rem !important;
  }

  .container {
    width: var(--container-width-sm);
  }

  h2 {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  h5 {
    font-size: 0.9rem;
  }
  .indent {
    margin-left: 0;
  }

}
